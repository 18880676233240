//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "product-card",
  props: {
    product: {
      type: Object,
      required: true,
    },
    categoryCode: {
      type: String,
      default: '',
    }
  },
  methods: {
    navigateToProduct(productLink) {
      this.$router.push({
        path: `/${productLink}`,
        query: {
          categoryCode: this.categoryCode
        }
      })
    },
    setImageURL(url) {
      let endOfString = url.indexOf('images/')
      if(endOfString+7 === url.length || url === ""){
        return '/UnavailableLarge.png'
      }
      return url
    },
  }
}
