import { render, staticRenderFns } from "./home.vue?vue&type=template&id=fb00306c&scoped=true&"
import script from "./home.vue?vue&type=script&lang=js&"
export * from "./home.vue?vue&type=script&lang=js&"
import style0 from "./home.vue?vue&type=style&index=0&id=fb00306c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../MultiSiteClient/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb00306c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../MultiSiteClient/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCarousel,VCarouselItem,VCol,VContainer,VIcon,VImg,VRow})
