//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductCard from '@/frontend/components/common/product-card.vue'

export default {
  name: 'ProductSwiper',
  components: {
    ProductCard,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      settings: {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1500,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1100,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
        ],
      },
    }
  },
  computed: {
    titleClass() {
      return this.title === 'new products' ? 'section-title-new' : '';
    }
  },
  methods: {
    navigateToProductList() {
      this.$router.push({
        path: '/catalog'
      })
    },
  },
}
