import { render, staticRenderFns } from "./product-card.vue?vue&type=template&id=073df382&scoped=true&"
import script from "./product-card.vue?vue&type=script&lang=js&"
export * from "./product-card.vue?vue&type=script&lang=js&"
import style0 from "./product-card.vue?vue&type=style&index=0&id=073df382&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../MultiSiteClient/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "073df382",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../MultiSiteClient/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
